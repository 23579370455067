import React, { Component, useEffect, useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import ContactForm from '../components/estimate-form'

import { ReviewForInModal } from '../components/reviews-all-modal-popup'
import PhotoGalleryComponent from '../components/photo-gallery-component'

import ReactCompareImage from '../components/before-after-image'
import { TestimonialVideo } from '../components/testimonial_video'

import Breadcrumbs from '../components/breadcrumbs'

import '../styles/projects-gallery.scss'

import Carousel, { Modal, ModalGateway } from 'react-images'
import Slider from 'react-slick'

import {
  SliderContainer,
  PrevArrow,
  NextArrow,
} from '../components/slider-components'
import { Maximize } from 'react-feather'

const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`
const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    line-height: 1.15;
    font-weight: 800;
    color: #000;
  }
  border-bottom: 1px solid #f1f1f1;
  background: #f7f7f7;
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

const ImageGalleryImage = ({
  fluid,
  fullScreen,
  i,
  setMaxHeight,
  maxHeight,
  windowHeight,
  windowWidth,
  isFullScreen,
  pagePath,
  paddingTop,
  activeImageState,
  ...otherprops
}) => {
  const gatsbyImgRef = useRef()

  return (
    <div
      className="aspect-ratio-box projects-project"
      ref={gatsbyImgRef}
      style={{
        paddingTop,
        display:
          i >= activeImageState - 1 && i <= activeImageState + 1
            ? 'block'
            : 'none',
      }} //lock aspect ratio with padding; no jumping because content size is provided and aspect ratio is computed for each page; lazy load images 3 images inView and images before and after
    >
      <GatsbyImage
        className="image-gallery-image overflow-hidden mb-0 aspect-ratio-box-inside"
        image={getImage(otherprops)}
        // onLoad={e =>
        //   updateActiveImageLoadingState(
        //     activeImageLoadingState.map(([index, state]) => {
        //       if (index == i) return [i, true]
        //       return [index, state]
        //     })
        //   )
        // }
        alt=""
      />
    </div>
  )
}

const settings = {
  accessibility: true,
  dots: true,
  infinite: true,
  // autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  // speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  fade: false,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const SliderCarousel = (props) => {
  const { images, activeImageState } = props
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const paddingTop =
    (images.length > 0 && images[0].gatsbyImageData
      ? (images[0].gatsbyImageData.height / images[0].gatsbyImageData.width) *
        100
      : 50) + '%'

  return (
    <SliderContainer className="project-gallery-container">
      {/* {showImageLoader && (
    <Loading className="w-14 h-14 absolute-center z-10" />
  )} */}
      <Slider
        {...settings}
        beforeChange={(oldIndex, newIndex) => setSelectedIndex(newIndex)}
      >
        {images.map((props, i) => (
          <ImageGalleryImage
            key={i}
            maxHeight={maxHeight}
            windowHeight={windowHeight}
            windowWidth={windowWidth}
            pagePath={props.path}
            isFullScreen={isFullScreen}
            setMaxHeight={setMaxHeight}
            paddingTop={paddingTop}
            activeImageState={selectedIndex}
            {...props}
          />
        ))}
      </Slider>
    </SliderContainer>
  )
}

const ProjectPage = (props) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const activeImageState = useState(
    props.data.contentfulProjects &&
      props.data.contentfulProjects.photoGallery &&
      props.data.contentfulProjects.photoGallery.photos &&
      props.data.contentfulProjects.photoGallery.photos.map((_, i) => [
        i,
        false,
      ])
  )
  const [activeImageLoadingState, updateActiveImageLoadingState] =
    activeImageState
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const toggleLightbox = (selectedIndex) => {
    setLightboxIsOpen(!lightboxIsOpen)
    setSelectedIndex(selectedIndex)
  }

  const { isLoading } = props

  const post = props.data.contentfulProjects
  const siteurl = props.data.site.siteMetadata.siteUrl
  const relatedPosts = []

  const { moreInCategory } = props.data

  const images =
    post &&
    post.photoGallery &&
    post.photoGallery.photos.map(
      ({ id, smallerFluid, file, fullscreen, title, ...rest }, i) => {
        return {
          i,
          id: i,
          caption: 'Grime Busters',
          src: fullscreen.src,
          w: 1200,
          h: 900,
          alt: title,
          thumbnail: smallerFluid.src,
          source: {
            original: file.url,
            regular: file.url,
            fullscreen: fullscreen.images.fallback.src,
          },
          file,
          smallerFluid,
          ...rest,
        }
      }
    )
  const items = images
  // const showImageLoader = !activeImageLoadingState[selectedIndex][1]

  return (
    <Layout pageProps={props}>
      <div className="bg-white border-b border-gray-100">
        <nav
          className="flex px-4 md:px-6 py-2  max-w-7xl mx-auto"
          aria-label="Breadcrumb"
        >
          <Breadcrumbs
            links={[
              { to: '/', label: 'Home' },
              { to: '/projects/', label: 'Projects' },
              {
                to: props.location.pathname,
                label: `${post.title} in ${post.city}, ${post.zipCode}`,
              },
            ]}
          />
        </nav>
      </div>
      <SEO
        title={
          post.metaTitle
            ? post.metaTitle
            : post.title +
              ' in ' +
              post.city +
              ', WA ' +
              post.zipCode +
              ' | Grime Busters'
        }
        description={
          post.metaDescription
            ? post.metaDescription
            : 'Check Out Photos of This Elegant ' +
              post.title +
              ' Project In ' +
              post.city +
              ' CA, ' +
              post.zipCode +
              ' Completed By Grime Busters.'
        }
        img={post.thumbnail.gatsbyImageData.images.fallback.src}
        twitterCardContent={'summary_large_image'}
      />
      <BlogPostLayout className="relative">
        <article itemScope itemType="https://schema.org/Article">
          <div
            itemProp="publisher"
            itemScope
            itemType="https://schema.org/Organization"
            className="hidden"
          >
            <span itemProp="name" className="hidden">
              Grime Busters
            </span>
            <span
              itemProp="image"
              itemScope
              itemType="http://schema.org/ImageObject"
              className="hidden"
            >
              <img
                src="https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg"
                className="hidden"
                width="262"
                itemProp="url"
              />
            </span>
            <span
              itemProp="logo"
              itemScope
              itemType="http://schema.org/ImageObject"
              className="hidden"
            >
              <img
                src="https://images.ctfassets.net/6c4iaavulnmy/TJqEt5ulPazetGtCkjwpm/3246ec7f0caede20b817352e0a811ea1/grimebusters-thumb3.jpg"
                className="hidden"
                width="262"
                itemProp="url"
              />
            </span>
          </div>
          <Header>
            <div className="lg:flex px-4 md:px-6 py-2 max-w-7xl lg:row mx-auto">
              <div
                className={`lg:container py-12 mx-auto text-center blogimgmw w-full lg:w-2/3 relative ${
                  isFullScreen ? 'z-20000' : 'z-20'
                }`}
                style={{ maxWidth: '900px' }}
              >
                <h1 itemProp="name headline" className="text-left -mt-3 mb-2">
                  {post.title} in {post.city}, {post.zipCode}
                </h1>
                {post.description && (
                  <p
                    className="text-left text-base text-gray-700 mb-6"
                    dangerouslySetInnerHTML={{
                      __html: post.description.description,
                    }}
                  />
                )}

                <div className="mx-auto overflow-hidden relative z-1 shadow-lg bg-white">
                  <div
                    itemProp="image"
                    itemScope
                    itemType="http://schema.org/ImageObject"
                  >
                    <SliderCarousel images={images} activeImageState={0} />
                  </div>
                  <div className="flex flex-1 items-stretch px-2 md:px-6">
                    <div className="flex items-center">
                      <button
                        className="flex items-center justify-self-end bg-white border-none text-gvd-500 font-bold outline-none cursor-pointer"
                        onClick={() => toggleLightbox(selectedIndex)}
                      >
                        <span className="hidden md:block text-black text-base tracking-widest uppercase">
                          FullScreen
                        </span>{' '}
                        <Maximize className="ml-2" size={20} />
                      </button>
                    </div>
                    <ModalGateway>
                      {lightboxIsOpen && (
                        <Modal
                          onClose={() => toggleLightbox(selectedIndex)}
                          allowFullscreen
                        >
                          <Carousel
                            currentIndex={selectedIndex}
                            frameProps={{ autoSize: '100vh' }}
                            styles={{
                              view: (base, state) => ({
                                ...base,
                                width: 'auto',
                              }),
                            }}
                            views={items}
                          />
                        </Modal>
                      )}
                    </ModalGateway>
                  </div>
                </div>
              </div>
              {/* <div className="w-full lg:w-1/3"></div> */}
            </div>
          </Header>
          {/* <div className="lg:flex lg:container lg:row mx-auto">
            <div className="w-full lg:w-1/3"></div>
            <div className="lg:container block lg:flex lg:row w-full h-full top-0 justify-end relative lg:absolute">
              <div className="w-full lg:w-2/3 invisible" />
              <div className="container w-full lg:w-1/3 py-12 relative justify-self-end p-4 lg:p-6 pt-14">
                <div className="p-6 rounded-xl bg-white shadow-lg sticky top-0">
                  <span className="block text-center text-base font-semibold mb-1">
                    Need Help With Your Project?
                  </span>
                  <h3 className="text-center">Reach Out To Us Today!</h3>
                  <div className="sidebarform">
                    <ContactForm
                      showLabels
                      showPlaceHolders
                      pageLocation={
                        props.location.href
                          ? props.location.href
                          : 'Not provided'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </article>
      </BlogPostLayout>
      {moreInCategory &&
      moreInCategory.edges &&
      moreInCategory.edges.length > 0 ? (
        <div className="container mx-auto p-4 py-8">
          <h3>
            Check out some of our other {post.categories[0].name} projects
          </h3>
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
            {moreInCategory &&
              moreInCategory.edges &&
              moreInCategory.edges.map((item, i) => (
                <Link
                  key={i}
                  to={`/projects/${item.node.slug}/`}
                  className="bg-white shadow hover:shadow-lg"
                >
                  <GatsbyImage
                    image={getImage(item.node.heroImage)}
                    style={{ height: '150px' }}
                    alt={item.node.title}
                  />
                  <div className="p-3 py-4 pt-3">
                    <span className="text-gray-700 text-base">
                      {item.node.publishDate}
                    </span>
                    <span className=" font-semibold leading-tight text-gray-900 block">
                      {item.node.title}
                    </span>
                    <div className="flex items-center text-gray-800 text-xs mt-1">
                      <div className="">
                        <img
                          src="/assets/location-pin.svg"
                          className="w-2 mb-0"
                        />
                      </div>
                      <div className="ml-2 text-xs">
                        {item.node.city}, OR {item.node.zipCode}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      ) : null}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectPage($slug: String!, $categoryId: [String]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulProjects(slug: { eq: $slug }) {
      id
      title
      slug
      categories: category {
        id
        name
        slug
      }
      date
      city
      zipCode
      thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      heroImage: thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      photoGallery {
        id
        photos {
          id
          smallerFluid: gatsbyImageData(
            quality: 70
            placeholder: NONE
            height: 1000
          )
          fullscreen: gatsbyImageData(quality: 80, width: 1874)
          file {
            url
          }
          gatsbyImageData(quality: 70, width: 1000, placeholder: NONE)
        }
      }
      description {
        id
        description
      }
      videoTestimonialRef {
        id
        thumbnail {
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
        video {
          file {
            url
            contentType
          }
        }
      }
      review {
        id
        source
        date
        reviewTitle
        reviewer
        source
        date
        category {
          id
          name
          slug
        }
        reviewBody {
          id
          reviewBody
        }
      }
    }
    moreInCategory: allContentfulProjects(
      filter: {
        category: { elemMatch: { id: { in: $categoryId } } }
        slug: { ne: $slug }
      }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          slug
          date
          city
          zipCode
          heroImage: thumbnail {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
          }
        }
      }
    }
  }
`

export default ProjectPage
